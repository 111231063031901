/* from here the new horizontal profit and loss stated */

/* horizontal table  */
#horizontal-PL-19_20 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#horizontal-PL-19_20 {
  border: 1px solid #ddd;
}

#horizontal-PL-19_20 th,
#horizontal-PL-19_20 td {
  padding: 8px;
  text-align: left;
}

#horizontal-PL-19_20 th,
#horizontal-PL-19_20 td {
  border-bottom: none;
  border-top: none;
}

#horizontal-PL-19_20 th {
  background-color: #f2f2f2;
}

#horizontal-PL-19_20 .section-header {
  background-color: #d9edf7;
  font-weight: bold;
  text-align: left;
}

#horizontal-PL-19_20 .footer {
  margin-top: 20px;
}

#horizontal-PL-19_20 .footer table {
  width: 100%;
  border: none;
}

#horizontal-PL-19_20 .footer td {
  border: none;
  padding: 5px 0;
}

#horizontal-PL-19_20 td input {
  border: 1px solid rgba(110, 108, 108, 0.1); /* Light black transparent border */
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-PL-19_20 th.no-border,
#horizontal-PL-19_20 td.no-border {
  border: none; /* Remove border for specific columns */
}

#horizontal-PL-19_20 tr.no-border td {
  border: none; /* Remove border for specific rows */
}

#horizontal-PL-19_20 th.no-border-r,
#horizontal-PL-19_20 td.no-border-r {
  border-right: none; /* Remove border for specific columns */
}

#horizontal-PL-19_20 tr.no-border-r td {
  border-right: none; /* Remove border for specific rows */
}

#horizontal-PL-19_20 th.no-border-l,
#horizontal-PL-19_20 td.no-border-l {
  border-left: none; /* Remove border for specific columns */
}

#horizontal-PL-19_20 tr.no-border-l td {
  border-left: none; /* Remove border for specific rows */
}
