body {
  font-family: Arial, sans-serif;
}

table {
  border-collapse: collapse;
  width: 100%;
}
table td {
  height: 18px;
}
th,
td {
  border: 1px solid #000;
  padding: 4px;
  text-align: left;
  border-left-width: 1.5px; /* Bolder vertical lines */
  border-right-width: 1.5px; /* Bolder vertical lines */
}

.yellow-background {
  background-color: #ffb402;
  border: #ffb402;
  font-size: 19px;
  font-weight: 600 !important;
}

.text-center {
  text-align: center;
}

.bold-underline,
.bold {
  font-weight: 600 !important;
}

.bold-underline {
  text-decoration: underline;
}

.bold-border-top {
  border-top-width: 2px;
}

.bold-border-bottom {
  border-bottom-width: 2px;
}

.hide-text {
  visibility: hidden;
}

.bordered-row td:first-child,
.bordered-row td:last-child {
  border-color: black !important; /* Adjust color and width as needed */
}

.no-border-right {
  border-right: none;
}

table#vertical-profit-loss td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

.non-editable {
  font-weight: 400;
}

.Second_heading {
  font-weight: 600;
}

#vertical-profit-loss td:nth-child(1) {
  width: 330px;
}

#vertical-profit-loss td:nth-child(6) {
  width: 280px;
}

/* Header */
#vertical-profit-loss td[colspan="11"] {
  text-align: center;
  background-color: #ffb402;
  font-size: 20px;
}

#vertical-profit-loss td[id="row-67"]:nth-child(3),
#vertical-profit-loss td[data-row="67"]:nth-child(5) {
  color: transparent !important;
}
#vertical-profit-loss tr:nth-child(29) td:nth-child(3) {
  visibility: hidden !important;
  background-color: #ff9204 !important;
}

/* second one  */

table#vertical-Balance-Sheet td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#vertical-Balance-Sheet tr:nth-child(46) td:nth-child(3),
#vertical-Balance-Sheet tr:nth-child(46) td:nth-child(2),
#vertical-Balance-Sheet tr:nth-child(46) td:nth-child(6),
#vertical-Balance-Sheet tr:nth-child(46) td:nth-child(5) {
  border: 3px solid #000;
}

#vertical-Balance-Sheet td:nth-child(1) {
  width: 330px;
}

#vertical-Balance-Sheet td:nth-child(6) {
  width: 280px;
}

/* Header */
#vertical-Balance-Sheet td[colspan="11"] {
  text-align: center;
  background-color: #ffb402;
  font-size: 20px;
}

/* thired page */
/* second one  */

table#vertical-annexures-cap-liab td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#vertical-annexures-cap-liab tr:nth-child(11) td:nth-child(10) {
  border-top: 2px solid #000;
}

#vertical-annexures-cap-liab td:nth-child(1) {
  width: 330px;
}

#vertical-annexures-cap-liab td:nth-child(6) {
  width: 280px;
}

/* Header */
#vertical-annexures-cap-liab .bgyellow {
  background-color: #ffb402;
  font-size: 16px;
}
.headerMain {
  padding: 2px 20px;
  background-color: #fff;
  border: 2px solid #000;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure the header stays above other content */
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 2px solid black;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 4px;
}

.nav-links a {
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  border: 4px solid transparent;
  border-radius: 2px;
  transition: border 0.3s;
  font-weight: 600;
}

.nav-links a:hover {
  border-bottom: 4px solid #ffb402;
}

.nav-links .active-link {
  border-bottom: 4px solid #ffb402;
}

.download-button {
  padding: 10px 20px;
  background-color: #ffb402;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: border 0.3s;
}

.download-button:hover {
  border-color: #ffb402;
}

/* horizental table  */
#hvertical-Balance-Sheet {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#hvertical-Balance-Sheet {
  border: 1px solid #ddd;
}

#hvertical-Balance-Sheet th,
#hvertical-Balance-Sheet td {
  padding: 8px;
  text-align: left;
}

#hvertical-Balance-Sheet th,
#hvertical-Balance-Sheet td {
  border-bottom: none;
  border-top: none;
}

#hvertical-Balance-Sheet th {
  background-color: #f2f2f2;
}

#hvertical-Balance-Sheet .section-header {
  background-color: #d9edf7;
  font-weight: bold;
  text-align: left;
}

#hvertical-Balance-Sheet .footer {
  margin-top: 20px;
}

#hvertical-Balance-Sheet .footer table {
  width: 100%;
  border: none;
}

#hvertical-Balance-Sheet .footer td {
  border: none;
  padding: 5px 0;
}

#hvertical-Balance-Sheet td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#hvertical-Balance-Sheet th.no-border,
#hvertical-Balance-Sheet td.no-border {
  border: none; /* Remove border for specific columns */
}

#hvertical-Balance-Sheet tr.no-border td {
  border: none; /* Remove border for specific rows */
}

#hvertical-Balance-Sheet th.no-border-r,
#hvertical-Balance-Sheet td.no-border-r {
  border-right: none; /* Remove border for specific columns */
}

#hvertical-Balance-Sheet tr.no-border-r td {
  border-right: none; /* Remove border for specific rows */
}
#hvertical-Balance-Sheet th.no-border-l,
#hvertical-Balance-Sheet td.no-border-l {
  border-left: none; /* Remove border for specific columns */
}

#hvertical-Balance-Sheet tr.no-border-l td {
  border-left: none; /* Remove border for specific rows */
}

#hvertical-Balance-Sheet tr:nth-child(1) {
  border-top: 2px solid #000;
}

#hvertical-Balance-Sheet tr:nth-child(4) {
  border-top: 2px solid #000;
}

#hvertical-Balance-Sheet tr:nth-child(5) {
  border-top: 2px solid #000;
  background-color: rgb(20, 218, 218);
}

#hvertical-Balance-Sheet tr:nth-child(6) {
  border-top: 2px solid #000;
}
#hvertical-Balance-Sheet tr:nth-child(48) {
  border-top: 2px solid #000;
}

#hvertical-Balance-Sheet tr:nth-child(5) {
  border-right: 2px solid #000;
}

#hvertical-Balance-Sheet td:nth-child(5) {
  border-right: 2px solid #000;
}

#hvertical-Balance-Sheet td:nth-child(1) {
  width: 10px;
}
#hvertical-Balance-Sheet td:nth-child(3) {
  width: 10px;
  text-align: center;
}

#hvertical-Balance-Sheet tr:nth-child(10) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(10) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(16) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(16) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(24) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(24) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(25) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(25) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(37) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(37) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(45) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(45) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(46) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(46) td:nth-child(4) {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
/* Header */
#hvertical-Balance-Sheet tr:nth-child(4) td {
  text-align: center;
  font-size: 20px;
}

#hvertical-Balance-Sheet td[colspan="3"] {
  text-align: center;

  font-size: 20px;
}
#hvertical-Balance-Sheet tr:nth-child(25) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(25) td:nth-child(4),
#hvertical-Balance-Sheet tr:nth-child(46) td:nth-child(5),
#hvertical-Balance-Sheet tr:nth-child(46) td:nth-child(4) {
  background-color: rgb(246, 165, 138);
}

/* button */

.layout-toggle-button {
  margin-right: 10px;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.layout-toggle-button.vertical {
  background-color: #ffb402;
  color: #000;
}

.layout-toggle-button.horizontal {
  background-color: rgb(20, 218, 218);
  color: #000;
}

.layout-toggle-button:hover {
  transform: scale(1.05);
}

.layout-toggle-button .fa-icon {
  margin-right: 8px;
  padding-right: 10px;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  margin-right: 8px;
}

/* new one */
table#vertical-annexures-assets td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#vertical-annexures-assets td:nth-child(1) {
  width: 300px;
  /* text-align: left !important; */
}

#vertical-annexures-assets td:nth-child(2) {
  width: 170px;
  /* text-align: right; */
}
#vertical-annexures-assets td:nth-child(5) {
  width: 140px;
  /* text-align: right; */
}
#vertical-annexures-assets td:nth-child(3) {
  width: 200px;
  /* text-align: right; */
}
#vertical-annexures-assets tr:nth-child(32) td:nth-child(8),
#vertical-annexures-assets tr:nth-child(32) td:nth-child(7),
#vertical-annexures-assets tr:nth-child(32) td:nth-child(6),
#vertical-annexures-assets tr:nth-child(32) td:nth-child(9),
#vertical-annexures-assets tr:nth-child(32) td:nth-child(10),
#vertical-annexures-assets tr:nth-child(32) td:nth-child(11) {
  visibility: hidden !important;
}

/* Header */
.bgyellow {
  background-color: #ffb402;
  font-weight: 600;
}

.bgpencil {
  background-color: #b3b3b3;
  font-weight: 600;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.center {
  text-align: center !important;
  font-size: 20px;
  font-weight: 600;
}

.free td[colspan="2"] {
  text-align: left !important;

  font-size: 16px !important;
}
.selected-cell {
  outline: none;
}

.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
}

.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #eee;
}

/* update code of the input field */

/* Style for the input fields */
input[type="text"] {
  outline: none; /* Remove the default outline */
}

input[type="text"]:focus {
  border: 1px solid #ffb402; /* Optional: Add a border color on focus */
  background-color: #f0f8ff; /* Optional: Change background on focus */
}

/* Add some styles for selected cells */
.selected-cell {
  background-color: #e7f0ff;
  border: 2px solid #ffb402;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid black;
  padding: 20px;
  z-index: 1000;
}
.popup button {
  display: block;
  margin: 5px;
}
.nav-links .table-link {
  text-decoration: none;
  color: inherit;
}

.nav-links .table-link.active {
  border-bottom: 4px solid #ffb402; /* Yellow border for active link */
  padding: 5px; /* Add padding if needed */
}

/* horizental table  */
#horizontal-Balance-Sheet {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#horizontal-Balance-Sheet {
  border: 1px solid #ddd;
}

#horizontal-Balance-Sheet th,
#horizontal-Balance-Sheet td {
  padding: 8px;
  text-align: left;
}

#horizontal-Balance-Sheet th,
#horizontal-Balance-Sheet td {
  border-bottom: none;
  border-top: none;
}

#horizontal-Balance-Sheet th {
  background-color: #f2f2f2;
}

#horizontal-Balance-Sheet .section-header {
  background-color: #d9edf7;
  font-weight: bold;
  text-align: left;
}

#horizontal-Balance-Sheet .footer {
  margin-top: 20px;
}

#horizontal-Balance-Sheet .footer table {
  width: 100%;
  border: none;
}

#horizontal-Balance-Sheet .footer td {
  border: none;
  padding: 5px 0;
}

#horizontal-Balance-Sheet td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-Balance-Sheet th.no-border,
#horizontal-Balance-Sheet td.no-border {
  border: none; /* Remove border for specific columns */
}

#horizontal-Balance-Sheet tr.no-border td {
  border: none; /* Remove border for specific rows */
}

#horizontal-Balance-Sheet th.no-border-r,
#horizontal-Balance-Sheet td.no-border-r {
  border-right: none; /* Remove border for specific columns */
}

#horizontal-Balance-Sheet tr.no-border-r td {
  border-right: none; /* Remove border for specific rows */
}
#horizontal-Balance-Sheet th.no-border-l,
#horizontal-Balance-Sheet td.no-border-l {
  border-left: none; /* Remove border for specific columns */
}

#horizontal-Balance-Sheet tr.no-border-l td {
  border-left: none; /* Remove border for specific rows */
}

#horizontal-Balance-Sheet tr:nth-child(1) {
  border-top: 2px solid #000;
}

#horizontal-Balance-Sheet tr:nth-child(4) {
  border-top: 2px solid #000;
}

#horizontal-Balance-Sheet tr:nth-child(5) {
  border-top: 2px solid #000;
  background-color: rgb(20, 218, 218);
}

#horizontal-Balance-Sheet tr:nth-child(6) {
  border-top: 2px solid #000;
}
#horizontal-Balance-Sheet tr:nth-child(48) {
  border-top: 2px solid #000;
}

#horizontal-Balance-Sheet tr:nth-child(5) {
  border-right: 2px solid #000;
}

#horizontal-Balance-Sheet td:nth-child(5) {
  border-right: 2px solid #000;
}

#horizontal-Balance-Sheet td:nth-child(1) {
  width: 10px;
}
#horizontal-Balance-Sheet td:nth-child(3) {
  width: 10px;
  text-align: center;
}

#horizontal-Balance-Sheet tr:nth-child(10) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(10) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(16) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(16) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(24) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(24) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(25) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(25) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(37) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(37) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(45) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(45) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(46) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(46) td:nth-child(4) {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
/* Header */
#horizontal-Balance-Sheet tr:nth-child(4) td {
  text-align: center;
  font-size: 20px;
}

#horizontal-Balance-Sheet td[colspan="3"] {
  text-align: center;

  font-size: 20px;
}
#horizontal-Balance-Sheet tr:nth-child(25) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(25) td:nth-child(4),
#horizontal-Balance-Sheet tr:nth-child(46) td:nth-child(5),
#horizontal-Balance-Sheet tr:nth-child(46) td:nth-child(4) {
  background-color: rgb(246, 165, 138);
}

/* button */

.layout-toggle-button {
  margin-right: 10px;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.layout-toggle-button.vertical {
  background-color: #ffb402;
  color: #000;
}

.layout-toggle-button.horizontal {
  background-color: rgb(20, 218, 218);
  color: #000;
}

.layout-toggle-button:hover {
  transform: scale(1.05);
}

.layout-toggle-button .fa-icon {
  margin-right: 8px;
  padding-right: 10px;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  margin-right: 8px;
}

/* from  hear the new horizental profit and loss stated */
/* horizental table  */
#horizontal-profit-loss {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#horizontal-profit-loss {
  border: 1px solid #ddd;
}

#horizontal-profit-loss th,
#horizontal-profit-loss td {
  padding: 8px;
  text-align: left;
}

#horizontal-profit-loss th,
#horizontal-profit-loss td {
  border-bottom: none;
  border-top: none;
}

#horizontal-profit-loss th {
  background-color: #f2f2f2;
}

#horizontal-profit-loss .section-header {
  background-color: #d9edf7;
  font-weight: bold;
  text-align: left;
}

#horizontal-profit-loss .footer {
  margin-top: 20px;
}

#horizontal-profit-loss .footer table {
  width: 100%;
  border: none;
}

#horizontal-profit-loss .footer td {
  border: none;
  padding: 5px 0;
}

#horizontal-profit-loss td input {
  border: 1px solid rgba(110, 108, 108, 0.1); /* Light black transparent border */
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-profit-loss th.no-border,
#horizontal-profit-loss td.no-border {
  border: none; /* Remove border for specific columns */
}

#horizontal-profit-loss tr.no-border td {
  border: none; /* Remove border for specific rows */
}

#horizontal-profit-loss th.no-border-r,
#horizontal-profit-loss td.no-border-r {
  border-right: none; /* Remove border for specific columns */
}

#horizontal-profit-loss tr.no-border-r td {
  border-right: none; /* Remove border for specific rows */
}
#horizontal-profit-loss th.no-border-l,
#horizontal-profit-loss td.no-border-l {
  border-left: none; /* Remove border for specific columns */
}

#horizontal-profit-loss tr.no-border-l td {
  border-left: none; /* Remove border for specific rows */
}

#horizontal-profit-loss tr:nth-child(1) {
  border-top: 2px solid #000;
}

#horizontal-profit-loss tr:nth-child(5) {
  border-top: 2px solid #000;
}

#horizontal-profit-loss tr:nth-child(6) {
  border-top: 2px solid #000;
  background-color: rgb(20, 218, 218);
}

#horizontal-profit-loss tr:nth-child(7) {
  border-top: 2px solid #000;
}
#horizontal-profit-loss tr:nth-child(35) {
  border-top: 2px solid #000;
}

#horizontal-profit-loss tr:nth-child(5) {
  border-right: 2px solid #000;
}

#horizontal-profit-loss td:nth-child(5) {
  border-right: 2px solid #000;
}

#horizontal-profit-loss td:nth-child(1) {
  width: 10px;
}
#horizontal-profit-loss td:nth-child(3) {
  width: 10px;
  text-align: center;
}

#horizontal-profit-loss tr:nth-child(9) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(9) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(16) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(16) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(21) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(21) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(32) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(32) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(26) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(26) td:nth-child(4) {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
#horizontal-profit-loss tr:nth-child(19) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(28) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(28) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(19) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(31) td:nth-child(4),
#horizontal-profit-loss tr:nth-child(31) td:nth-child(5) {
  border-top: 2px solid #000;
}
/* Header */
#horizontal-profit-loss tr:nth-child(5) td {
  text-align: center;
  font-size: 20px;
}

#horizontal-profit-loss td[colspan="3"] {
  text-align: center;

  font-size: 20px;
}
#horizontal-profit-loss tr:nth-child(46) td:nth-child(5),
#horizontal-profit-loss tr:nth-child(46) td:nth-child(4) {
  background-color: rgb(246, 165, 138);
}

/* button */
/* horizental note 3-6 */
table#horizontal-BS-1_3 td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-BS-1_3 {
  border: 1px solid #ddd;
}

#horizontal-BS-1_3 th,
#horizontal-BS-1_3 td {
  padding: 5px;
  text-align: left;
}

#horizontal-BS-1_3 th,
#horizontal-BS-1_3 td {
  border-bottom: none;
}

#horizontal-BS-1_3 th {
  background-color: #f2f2f2;
}

#horizontal-BS-1_3 .footer {
  margin-top: 20px;
}

#horizontal-BS-1_3 .footer table {
  width: 100%;
  border: none;
}

#horizontal-BS-1_3 .footer td {
  border: none;
  padding: 5px 0;
}

#horizontal-BS-1_3 tr:nth-child(46) td:nth-child(5),
#horizontal-BS-1_3 tr:nth-child(46) td:nth-child(4) {
  background-color: rgb(246, 165, 138);
}

/* from hare the bs 4 and 6 will stated  */
table#horizontal-Bs-4_6 td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-Bs-4_6 {
  border: 1px solid #ddd;
}

#horizontal-Bs-4_6 th,
#horizontal-Bs-4_6 td {
  padding: 5px;
  text-align: left;
}

#horizontal-Bs-4_6 th,
#horizontal-Bs-4_6 td {
  border-bottom: none;
}

#horizontal-Bs-4_6 th {
  background-color: #f2f2f2;
}

#horizontal-Bs-4_6 .footer {
  margin-top: 20px;
}

#horizontal-Bs-4_6 .footer table {
  width: 100%;
  border: none;
}

#horizontal-Bs-4_6 tr:nth-child(46) td:nth-child(5),
#horizontal-Bs-4_6 tr:nth-child(46) td:nth-child(4) {
  background-color: rgb(246, 165, 138);
}
/* end of the 4-6 */

/* from hear the bs 11 will stated  */
table#horizontal-BS-11 td input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-BS-11 {
  border: 1px solid #ddd;
}

#horizontal-BS-11 th,
#horizontal-BS-11 td {
  padding: 5px;
  text-align: left;
}

#horizontal-BS-11 th,
#horizontal-BS-11 td {
  border-bottom: none;
}

#horizontal-BS-11 th {
  background-color: #f2f2f2;
}

#horizontal-BS-11 .footer {
  margin-top: 20px;
}

#horizontal-BS-11 .footer table {
  width: 100%;
  border: none;
}

/* end of the bs 11 */
/* bs7 stated  */
/* from  hear the new horizental profit and loss stated */
/* horizental table  */
#horizontal-BS-7_10 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#horizontal-BS-7_10 {
  border: 1px solid #ddd;
}

#horizontal-BS-7_10 th,
#horizontal-BS-7_10 td {
  padding: 8px;
  text-align: left;
}

#horizontal-BS-7_10 th,
#horizontal-BS-7_10 td {
  border-bottom: none;
  border-top: none;
}

#horizontal-BS-7_10 th {
  background-color: #f2f2f2;
}

#horizontal-BS-7_10 .section-header {
  background-color: #d9edf7;
  font-weight: bold;
  text-align: left;
}

#horizontal-BS-7_10 .footer {
  margin-top: 20px;
}

#horizontal-BS-7_10 .footer table {
  width: 100%;
  border: none;
}

#horizontal-BS-7_10 .footer td {
  border: none;
  padding: 5px 0;
}

#horizontal-BS-7_10 td input {
  border: 1px solid rgba(110, 108, 108, 0.1); /* Light black transparent border */
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-BS-7_10 th.no-border,
#horizontal-BS-7_10 td.no-border {
  border: none; /* Remove border for specific columns */
}

#horizontal-BS-7_10 tr.no-border td {
  border: none; /* Remove border for specific rows */
}

#horizontal-BS-7_10 th.no-border-r,
#horizontal-BS-7_10 td.no-border-r {
  border-right: none; /* Remove border for specific columns */
}

#horizontal-BS-7_10 tr.no-border-r td {
  border-right: none; /* Remove border for specific rows */
}
#horizontal-BS-7_10 th.no-border-l,
#horizontal-BS-7_10 td.no-border-l {
  border-left: none; /* Remove border for specific columns */
}

#horizontal-BS-7_10 tr.no-border-l td {
  border-left: none; /* Remove border for specific rows */
}

/* end of the bs7  */
/* start bs-12_18 */
/* from  hear the new horizental profit and loss stated */
/* horizental table  */
#horizontal-BS-12_18 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

#horizontal-BS-12_18 {
  border: 1px solid #ddd;
}

#horizontal-BS-12_18 th,
#horizontal-BS-12_18 td {
  padding: 8px;
  text-align: left;
}

#horizontal-BS-12_18 th,
#horizontal-BS-12_18 td {
  border-bottom: none;
  border-top: none;
}

#horizontal-BS-12_18 th {
  background-color: #f2f2f2;
}

#horizontal-BS-12_18 .section-header {
  background-color: #d9edf7;
  font-weight: bold;
  text-align: left;
}

#horizontal-BS-12_18 .footer {
  margin-top: 20px;
}

#horizontal-BS-12_18 .footer table {
  width: 100%;
  border: none;
}

#horizontal-BS-12_18 .footer td {
  border: none;
  padding: 5px 0;
}

#horizontal-BS-12_18 td input {
  border: 1px solid rgba(110, 108, 108, 0.1); /* Light black transparent border */
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}

#horizontal-BS-12_18 th.no-border,
#horizontal-BS-12_18 td.no-border {
  border: none; /* Remove border for specific columns */
}

#horizontal-BS-12_18 tr.no-border td {
  border: none; /* Remove border for specific rows */
}

#horizontal-BS-12_18 th.no-border-r,
#horizontal-BS-12_18 td.no-border-r {
  border-right: none; /* Remove border for specific columns */
}

#horizontal-BS-12_18 tr.no-border-r td {
  border-right: none; /* Remove border for specific rows */
}
#horizontal-BS-12_18 th.no-border-l,
#horizontal-BS-12_18 td.no-border-l {
  border-left: none; /* Remove border for specific columns */
}

#horizontal-BS-12_18 tr.no-border-l td {
  border-left: none; /* Remove border for specific rows */
}

/* end bs -12-18 */

.bgblue {
  background-color: rgb(20, 218, 218);
  font-size: 18px !important;
}
.border-transparent {
  border: transparent !important;
}
.border-cstransparent {
  border-bottom: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
}

.bgblue {
  background-color: rgb(20, 218, 218);
  font-size: 18px !important;
}

/* transparent border  */
.trans-border {
  border: transparent;
}
.text-blue {
  color: rgb(20, 218, 218);
}
.topButtonBorder {
  border-top: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
}
.topBd {
  border-top: 2px solid #000 !important;
}
.btbd {
  border-bottom: 2px solid #000 !important;
}

.trans-text {
  color: #fff !important; /* Makes the text invisible */
  visibility: hidden !important; /* Hides the element */
  height: 0; /* Optional: Prevent the element from occupying space */
  overflow: hidden; /* Optional: Hide any overflowing content */
}
.color_white {
  color: red !important;
}

.hideen-border {
  border: transparent !important;
}
.hiddeen-btton-border {
  border-bottom: transparent !important;
}
.hiddeen-top-border {
  border-top: transparent !important;
}
.hiddeen-btton-top-border {
  border-bottom: transparent !important;
  border-top: transparent !important;
}
/* new */

.centerYelow {
  text-align: center;
  background-color: #ffb402;
  font-size: 20px;
}

select {
  font-size: 1em;
  border: 1px solid #ff9204;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  width: 100%;
  color: #000;
  font-weight: 800;
}

.Move-btn {
  background-color: #ffb402;
  border-color: #ffb402;
}
.Move-btn:hover {
  background-color: #ff9204;
  border-color: #ff9204;
}
/* Download button styles */
.download-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.download-select {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ff9204;
  color: #000;
  font-weight: 800;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.download-select:hover {
  border-color: #ffb402;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.download-select:focus {
  outline: none;
  border-color: #ffb402;
  box-shadow: 0 0 0 2px rgba(255, 180, 2, 0.2);
}

.download-select option {
  font-weight: normal;
  background-color: #ffffff;
}

.download-select option:disabled {
  color: #999;
}

#vertical-profit-loss tr:nth-child(36) td:nth-child(3),
#vertical-profit-loss tr:nth-child(36) td:nth-child(5) {
  color: white;
}
/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e8e8e8;
  z-index: 1000;
}

.loading {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading::before,
.loading::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 6px solid #e5e5e5;
  border-top-color: #ff9204;
  animation: spin 1s ease-in-out infinite;
}

.loading::after {
  border: 6px solid #e5e5e5;
  border-bottom-color: #ff9204;
  animation-direction: reverse;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 40px);
  margin-top: 12px;
  font-size: 18px;
  color: #383737;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  font-weight: 500;
  text-align: center;
  animation: fade 1.5s ease-in-out infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
}

.saving-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears above other content */
}

.saving-message {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.containerMapping {
  height: 80vh;
  overflow: auto;
  padding: 20px;
  background: linear-gradient(to bottom, #f9f9f9, #eaeaea);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #ddd;
  transition: transform 0.2s, box-shadow 0.2s;
}

.containerMapping::-webkit-scrollbar {
  width: 10px;
}

.containerMapping::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

.containerMapping::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.containerMapping::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.edit-container-main {
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}
