/* src/Popup.css */
.popup {
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6); /* Dark background */
  z-index: 1000; /* Ensure it's above everything */
  position: fixed; /* Make sure it's over the entire window */
  width: 100%;
  height: 100%;
}

.popup.show {
  display: flex;
}

.popup-content {
  background-color: white;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

.close-btn {
  position: absolute;
  top: 1px;
  right: 2px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #ffb402;
}

.open-calculator-btn {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #ffb402;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.close-btn:hover {
  color: #f5af0a;
}

.open-calculator-btn:hover {
  background-color: #f5af0a;
}

/* Main content styling */
.main-content {
  margin-top: 20px;
  transition: filter 0.3s ease; /* Smooth transition when blurring */
  z-index: 1; /* Ensure it's beneath the popup */
}

/* Blur effect applied to the main content */
.blur {
  filter: blur(5px);
}
