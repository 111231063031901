.calculator {
  width: 400px;
  padding: 20px;
  background: linear-gradient(145deg, #ffffff, #fefbf7);
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* .calculator.dark {
  background: linear-gradient(145deg, #2f3640, #353b48);
  color: #ecf0f1;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
} */

.display {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  font-size: 24px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #f2f2f2;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* .calculator.dark .input-field {
  background: #3b3b3b;
} */

.blinking-cursor {
  position: absolute;
  right: 10px;
  font-size: 24px;
  color: #ffb402;
  animation: blink 1s step-end infinite;
}

.result {
  font-size: 22px;
  text-align: right;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* .calculator.dark .result {
  background: #2c3e50;
  color: #ecf0f1;
} */

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.buttons button {
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  background: #ffb402;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.buttons button:hover {
  background: #e09b00;
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.buttons button:active {
  transform: translateY(1px);
  box-shadow: none;
}

.equal {
  grid-column: span 4;
  background: #ff9800;
}

.toggle-mode {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-mode button {
  padding: 10px;
  background: none;
  border: none;
  color: #ffb402;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.toggle-mode button:hover {
  color: #ff9800;
}

.history {
  margin-top: 20px;
}

.history h3 {
  font-size: 18px;
  text-align: center;
}

.history ul {
  padding: 0;
  list-style: none;
}

.history li {
  font-size: 16px;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

/* .calculator.dark .history li {
  color: #ecf0f1;
  border-bottom: 1px solid #34495e;
} */
