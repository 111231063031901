/* Login.css */

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: #f7f9fc;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Blurs the background */
  z-index: 999; /* Just below the popup */
}

.create-table-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  max-width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Above the overlay */
  animation: fadeIn 0.5s ease-in-out;
  backdrop-filter: none; /* Removes blur effect from the popup */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #ff5c5c;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.close-button:hover {
  transform: scale(1.2);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

label {
  font-weight: bold;
  color: #555;
}

.table-name-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.table-name-input:focus {
  border-color: #007bff;
  outline: none;
}

.create-table-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  width: 15vw !important;
  justify-content: center !important;
}

.create-table-button:hover {
  background-color: #0056b3;
}

.create-table-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Keyframes for gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Keyframes for form fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Login page styling */
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background: linear-gradient(
    45deg,
    #fb9668,
    /* Light Blue */ #f99f55,
    /* Lighter Blue */ #fbdbc2,
    /* Pink */ #f7a35e,
    /* Light Pink */ #fab486
  );
  background-size: 200% 200%; /* Reduced size for lighter animation effect */
  animation: gradientAnimation 30s ease infinite; /* Slower animation for a calming effect */
  background-repeat: no-repeat;
  background-attachment: fixed; /* Keeps the background in place on scroll */
  position: relative;
  overflow: hidden;
}

.login-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Light blur effect */
  backdrop-filter: blur(10px); /* Actual blur */
  z-index: -1; /* Ensures this is behind other elements */
}

.login-image {
  flex: 1;
  display: none; /* Hide image in smaller screens for responsiveness */
}

.login-image img {
  width: 75%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.login-container {
  flex: 1;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.9); /* Slight transparency */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-container h2 {
  color: #333;
  margin-bottom: 10px;
  font-size: 24px;
}

.login-container p {
  color: #777;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.input-field:focus {
  border-color: #ffb402;
  outline: none;
  box-shadow: 0 0 5px rgba(255, 180, 2, 0.5);
}

.login-button {
  width: 107%;
  padding: 12px;
  background-color: #ffb402;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #ff9800;
}

.register-text {
  margin-top: 20px;
  color: #555;
}

.register-button {
  background: none;
  border: none;
  color: #ff7e5f;
  cursor: pointer;
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
}

.success-message {
  color: green;
  margin-bottom: 10px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .login-image {
    display: block;
    flex: 1;
    max-width: 50%;
    overflow: hidden;
  }

  .login-container {
    flex: 1;
    max-width: 400px;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

/* table */
.table-list-container {
  padding: 2vw;
  background-color: #f4f4f4;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  min-height: 92vh;
  margin: 0 auto;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  justify-content: center;
}

.search-label {
  font-size: 14px;
  color: #555;
  font-weight: 500;
}

.search-input {
  padding: 10px 15px;
  width: 250px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #ffb402;
  outline: none;
}

.create-table-button {
  background-color: #ffb402;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-table-button:hover {
  background-color: #5bc0de;
}

.loading-container {
  text-align: center;
  padding: 50px 0;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

.tableList {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.tableList th,
.tableList td {
  padding: 12px 15px;
  text-align: left;
}

.tableList th {
  background-color: #ffb402;
  color: white;
  cursor: pointer;
}

.tableList th:hover {
  background-color: #5bc0de;
}

.tableList tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.tableList tbody tr:hover {
  background-color: #f1f1f1;
}

.table-item-actions {
  display: flex;
  gap: 10px;
}

.delete-button {
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.delete-button:hover {
  background-color: #c9302c;
}

.view-button {
  background-color: #5bc0de;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 12px 15px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.view-button:hover {
  background-color: #31b0d5;
}

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  gap: 5px;
}

.pagination-button {
  background-color: #ffb402;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button.active {
  background-color: #5bc0de;
}

.pagination-button:hover {
  background-color: #ff4b00;
}

.pagination-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-100vh);
  transition: transform 0.3s ease-in-out;
}

.modal-content.show {
  transform: translateY(0);
}
.sc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
}

.table-item:hover {
  background-color: #333;
}

.tableList td {
  height: 4vh;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* High z-index to overlay everything */
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5vh;
  font-size: 1.4em;
}
.headb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headb h2 {
  color: #333; /* Darker text color */
  font-family: "Arial", sans-serif; /* Font style */
  font-weight: 600;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Make the button square */
  height: 40px;
  border-radius: 50%; /* Make buttons circular */
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition */
}

.btn-primary {
  background-color: #ff9800; /* Primary color */
  color: #fff;
}

.btn-secondary {
  background-color: #5bc0de; /* Secondary color */
  color: #fff;
}

.btn:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.success-popup {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745; /* Green background for success */
  color: #fff; /* White text */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px;
  text-align: center;
  animation: fadeInOut 3s forwards; /* Animate in and out */
}

/* Animation keyframes for pop-up */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Start above */
  }
  10%,
  90% {
    opacity: 1;
    transform: translateY(0); /* Centered */
  }
  100% {
    opacity: 0;
    transform: translateY(-20px); /* Move above and fade out */
  }
}
.topbtn {
  display: flex;
  gap: 10px;
}

/* header */

/* Header styles */
.nav-links.horizontal .table-link {
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}
.nav-links.vertical .table-link {
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.userButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* User profile and dropdown */
.user-profile {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.user-icon {
  font-size: 30px;
  color: #333;
}

.user-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.user-dropdown.show {
  display: block;
}

.user-dropdown p {
  margin: 5px 0;
}

.user-dropdown button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}
