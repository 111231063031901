/* styles.css */
.header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #ff6600;
  border: 1px solid #333;
  margin-bottom: 2vh;
  border-radius: 4px;
}

.logo-container .logo {
  height: 50px;
}

.user-container {
  position: relative;
}

.user-icon {
  font-size: 40px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.dropdown-menu p {
  margin: 5px 0;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 5px 0;
  cursor: pointer;
  color: #333;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}
